import moment from 'moment';
import { useCookies } from 'react-cookie';
import { isPartner } from 'src/client/utils/affiliateTracking';
import { useSelector } from '../redux';
import { MAM_COOKIE_NAME } from 'src/shared/constants/cookies';

export const usePromotions = () => {
  const { partnerIDs, promotions, splash } = useSelector((state) => state.app.config);
  const { user } = useSelector((state) => state.auth);
  const [cookies] = useCookies([MAM_COOKIE_NAME]);

  const startDate = moment(promotions?.promoCodeStart);
  const endDate = moment(promotions?.promoCodeEnd);
  const isCurrentTimeBetweenStartAndEndDates = moment().isBetween(startDate, endDate, undefined, '[]');

  const popupDisabledPaths = [
    '/',
    '',
    ...(splash?.active ? [] : ['/updates', '/updates/archive', '/updates/upcoming']),
  ];

  // Promo is considered active if:
  //   1. Promotions are enabled on the current site through the database
  //   2. Current time falls between the start and end dates of the promo
  //   3. User has no MAM cookie or their MAM code is not blacklisted for promos
  const isPromoActive =
    !!promotions?.enabled && isCurrentTimeBetweenStartAndEndDates && !isPartner(cookies[MAM_COOKIE_NAME], partnerIDs);

  const isUserWithSubscription = !!user?.validSubscription;

  // Specific promo sections are active if
  //   1. Promo is considered enabled (all of the above is true)
  //   2. Guest user or user WITHOUT a valid subscription on the current site
  //   3. Their respective section option is set to true in the promotions object in the config database
  const isPromoBannerActive = isPromoActive && !!promotions?.bannerEnabled && !isUserWithSubscription;
  const isPromoInlineBannerActive = isPromoActive && !!promotions?.inlineBannerEnabled && !isUserWithSubscription;
  const isPromoFooterActive = isPromoActive && !!promotions?.footerEnabled && !isUserWithSubscription;
  const isPromoPopupActive = isPromoActive && !!promotions?.popupEnabled && !isUserWithSubscription;
  const isPromoRibbonActive = isPromoActive && !!promotions?.ribbonEnabled && !isUserWithSubscription;
  const isPromoSplashFooterActive = isPromoActive && !!promotions?.splashFooterEnabled && !isUserWithSubscription;

  // Promo gateway is active if
  //   1. Promo is considered enabled (all of the above is true)
  //   2. User WITH a valid subscription on the current site
  //   3. `gatewayEnabled` is set to true on the promotions object in the config database
  //   Note: if promo gateway is not enabled, the default gateway will be shown
  const isPromoGatewayActive = isPromoActive && !!promotions?.gatewayEnabled && isUserWithSubscription;

  return {
    popupDisabledPaths, // this default will be overriden if promotions config has it set on the next line
    ...promotions,
    isPromoActive,
    isPromoBannerActive,
    isPromoInlineBannerActive,
    isPromoFooterActive,
    isPromoGatewayActive,
    isPromoPopupActive,
    isPromoRibbonActive,
    isPromoSplashFooterActive,
  };
};
